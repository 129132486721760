import type { NormalizedConcept } from 'shared/features/concepts/concepts.apiV2.types';

export const NEW_CONCEPT_ID = 'new';

export const META_CONCEPTS_COUNT_PER_REQUEST = 20;

export const CONCEPT_DETAILS_DEFAULT: NormalizedConcept = {
  name: '',
  id: NEW_CONCEPT_ID,
  description: '',
  advancedSearch: '',
  metaConcept: null,
  isRequirementType: false,
  isActive: false,
  createdAt: '',
  updatedAt: ''
};

export const ERRORS = {
  REQUIRED_FIELD: 'This field is required'
};

export const DOCUMENT_REQUEST_FIELDS = [
  'count',
  'items.id',
  'items.pipeline_status',
  'items.title',
  'items.jurisdiction',
  'items.jurisdiction_id',
  'items.diffable'
];

export const REQUIREMENT_TYPES_ERRORS = {
  WRONG_DOCUMENT_JURISDICTION:
    'All documents should have the same jurisdiction and should be equal requirement type jurisdiction',
  WRONG_DOCUMENT_STATUS: 'All documents should have processed status',
  DOCUMENT_SEARCH: 'Looks like one of provided document does not exist'
};

export enum TABLE_FIELDS {
  DOC_ID = 'doc_id',
  ID = 'id',
  TITLE = 'title',
  PIPELINE_STATUS = 'pipeline_status',
  JURISDICTION = 'jurisdiction',
  SENTENCES = 'diffable'
}
